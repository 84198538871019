@import "media";
// == SIZES ==
$container-width: 1170px!important;
$header-height: 120px;
$vieport-width: 100vw;
$closer-width: 850px;


// == POSITIONS ==
$block: block;
$inline-block: inline-block;
$inline: inline;
$table: table;
$table-cell: table-cell;
$body-padding: 70px 0px;
$footer-padding: 50px 20px;

// == SHADOWS ==
$box-shadow: 0px 0px 16px rgba(139, 139, 139, 0.5);



/*BORDER*/
.border-{
	&left{
		border-left: 1px solid $border-color;
	}
	&right{
		border-right: 1px solid $border-color;
	}
	&top{
		border-top: 1px solid $border-color;
	}
	&bottom{
		border-bottom: 1px solid $border-color;
	}	
	&md-{
		@include respond-from(md){
			&right{
				border-right: 1px solid $border-color;
			}
			&left{
				border-left: 1px solid $border-color;
			}
			&bottom{
				border-bottom: 1px solid $border-color;
			}
			&top{
				border-top: 1px solid $border-color;
			}
		}
	}
	&lg-{
		@include respond-from(lg){
			&right{
				border-right: 1px solid $border-color;
			}
			&left{
				border-left: 1px solid $border-color;
			}
			&bottom{
				border-bottom: 1px solid $border-color;
			}
			&top{
				border-top: 1px solid $border-color;
			}
		}
	}
}


.z-index-{
	&1{
		z-index: 1 !important;
	}
	&2{
		z-index: 2 !important;
	}
	&3{
		z-index: 3 !important;
	}
	&4{
		z-index: 4 !important;;
	}
	&5{
		z-index: 5;
	}
	&999{
		z-index: 999;
	}


	&n1{
		z-index: -1;
	}
	&n2{
		z-index: -2;
	}


}


.media-img{
	height:100%;
	position: relative;
    overflow: hidden;
	.full-img{

		    position: absolute;
		    min-width: 100%;
		    left: 50%;
		    top: 50%;
		    transform: translate(-50%,-50%);
		    min-height: 100%;
		    max-width: 120%;
		    z-index: 0;
		    width: auto;
	}
}

.content-absolute{
	@include respond-from(lg){
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-left: 15px;
    padding-right: 15px;
	}
}


.overflow-hidden{
	overflow:hidden !important;
}

.position-lg-absolute{
	@include respond-from(lg){
		position: absolute !important;
	}
}


