@font-face {
	font-family: Icons;
	src: url(./fonts/icons/Icons.woff);
}

@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i&amp;subset=latin-ext);


//=====FONT=======//
$primary-font: 'Poppins', sans-serif;
$text-font: $primary-font;


$headline-font: $primary-font;

//=====WEIGHT-FONT=======//

$weight-thin: 200 !important;
$weight-normal: 300 !important;
$weight-strong: 600 !important;
$extra-weight-strong: 900 !important;


//=====HEADLINE-FONT=======//
$h1-font-size: 48px;
$h2-font-size: 48px;
$h3-font-size: 38px;
$h4-font-size: 34px;

	// MEDIA
	//TABLET - PHONE

	$h1-font-size-mob: 30px ;
	$h2-font-size-mob: 28px;
	$h3-font-size-mob: 28px;
	$h3-font-size-mob-xs: 20px;
	$h4-font-size-mob: 24px;



//=====HEADLINE-WEIGHT=======//

$h1-font-weight: $weight-thin ;
$h2-font-weight: $weight-strong ;
$h3-font-weight: $weight-strong ;
$h4-font-weight: $weight-strong ;

//=====PARAGRAPH=======//

$text-size: 18px;
$text-weight: $weight-normal;
		// MEDIA
		//TABLET - PHONE

		$text-size-mob: 16px;



//=====HEADER-FONT=======//

$navbar-size: 20px;
$link-size: 14px;

$line-height: 1.5!important;


//=================CLASS====================//


.primary-font{
	font-family: $primary-font;
}


.weight-{
	&thin{
		font-weight:$weight-thin;
	}
	&normal{
		font-weight:$weight-normal;
	}
	&strong{
		font-weight: $weight-strong;
	}
	&strong-extra{
		font-weight: $extra-weight-strong;
	}
}

.weight-strong-icon{

	&:before{
		font-weight: $weight-strong;
	}
}

//Paragraphs

p{
	@include font-size($text-size-mob);
	@include respond-from(md){
		@include font-size($text-size);
	}
	@include respond-for(landscape){
		@include font-size($text-size-mob);
	}
	font-family: $primary-font;
	line-height: $line-height;
	color: $text-color;
}

a{	
	color: $link-color;

	&:hover{
		transition: all 0.4s ease-out;
		text-decoration: none;
		color:$primary-color !important;
		font-weight: $weight-strong;
		cursor: pointer;
	}
}
.navbar {

	a{
		color: $primary-color;
	}
}

li, table{
	font-family: $primary-font;
	font-weight: $weight-normal;
	
}

//Headlines

h1{
	font-family: $primary-font;
	font-weight: $h1-font-weight;
	color: $h1-color;
	@include font-size($h1-font-size-mob);
	@include respond-from(md){
		@include font-size($h1-font-size);
	}
	span{
		font-weight: $weight-strong;
	}

}

h2{
	font-family: $primary-font;
	font-weight: $h2-font-weight;
	color: $h2-color;
	@include font-size($h2-font-size-mob);
	@include respond-from(md){
		@include font-size($h2-font-size);
	}
	@include respond-for(landscape){
		@include font-size($h2-font-size-mob);
	}
}
h3{
	font-family: $primary-font;
	font-weight: $h3-font-weight;
	color: $h3-color;
	@include font-size($h3-font-size-mob-xs);
	@include respond-from(sm){
		@include font-size($h3-font-size-mob);
	}
	@include respond-from(md){
		@include font-size($h3-font-size);
	}
	@include respond-for(landscape){
		@include font-size($h3-font-size-mob);
	}
}
h4{
	font-family: $primary-font;
	font-weight: $h4-font-weight;
	color: $h4-color;
	@include font-size($h4-font-size-mob);
	@include respond-from(md){
		@include font-size($h4-font-size);
	}
	@include respond-for(landscape){
		@include font-size($h4-font-size-mob);
	}
	
}

.uppercase{
	text-transform: uppercase;
}

// LINE HEIGHT

.line-1{
	line-height: 1!important;
}
.line-1_5{
	line-height: 1.5!important;
}
.line-2{
	line-height: 2!important;
}
.line-2_5{
	line-height: 2.5!important;
}

.text-md-lowercase{
	@include respond-from(md){
		text-transform: lowercase !important;
	}
}