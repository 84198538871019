
//============DEFINITION============//

// PRIMARY COLORS
$primary-color: #1dbed7;
$secondary-color: #000000;

// TEMPLATE NEXT COLORS

$color-grey1: #cccccc;
$color-grey2: #f6f6f6;

// TEXT COLOR
$text-color: #666666;

$h1-color: #fff;
$h2-color: $secondary-color;
$h3-color: $secondary-color;
$h4-color: $secondary-color;

// LINK COLORS
$link-color: $primary-color;

// BACKGROUNDS COLORS
$body-color: #787b8d !important;
$header-background: transparent;
$footer-background-color: transparent;

// BORDER COLORS
$border-color: rgba(139, 139, 139, 0.5);

//ADVANTAGE COLORS
$color-white: #fff;
$color-black: #000;
$grey-grey: #f9f9f9;



//============CLASS============//

.color-{
	&primary{
		color: $primary-color !important;
	}
	&secondary-color{
		color: $secondary-color !important;
	}
	&white{
		color: $color-white !important;
	}
}
.bg-{
	&primary{
		background-color: $primary-color;
	}
	&secondary{
		background-color: $secondary-color !important;
	}
	&white{
		background-color: $color-white;
	}
	&black{
		background-color: $color-black;
	}
}
