@font-face {
	font-family: "NGS_font";
	src: url('../fonts/NGS_font.eot');
	src: url('../fonts/NGS_font.eot?#iefix') format('eot'),
		url('../fonts/NGS_font.woff2') format('woff2'),
		url('../fonts/NGS_font.woff') format('woff'),
		url('../fonts/NGS_font.ttf') format('truetype'),
		url('../fonts/NGS_font.svg#NGS_font') format('svg');
}

.icon:before {
	font-family: "NGS_font";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-close-icon:before {
	content: "\E001";
}

.icon-mail-icon-blue:before {
	content: "\E002";
}

.icon-mail-icon-white:before {
	content: "\E003";
}

.icon-map-pointer:before {
	content: "\E004";
}

.icon-menu-icon:before {
	content: "\E005";
}

.icon-mouse:before {
	content: "\E006";
}

.icon-ngs-logo-footer:before {
	content: "\E007";
}

.icon-ngs-logo-menu:before {
	content: "\E008";
}

.icon-phone-icon-blue:before {
	content: "\E009";
}

.icon-phone-icon-white:before {
	content: "\E00A";
}

.icon-reference-arcibiskupstvi:before {
	content: "\E00B";
}

.icon-reference-cmbeton:before {
	content: "\E00C";
}

.icon-reference-kia:before {
	content: "\E00D";
}

.icon-reference-mastercard:before {
	content: "\E00E";
}

.icon-reference-mentos:before {
	content: "\E00F";
}

.icon-reference-pepsi:before {
	content: "\E010";
}

.icon-reference-samson:before {
	content: "\E011";
}

.icon-reference-skoda:before {
	content: "\E012";
}
