$break-xsmall: 320px;
$break-small: 575px;
$break-medium: 767px;
$break-big: 991px;


@mixin respond-to($media) {
  @if $media == big {
    @media only screen and (max-width: $break-big) { @content; }
  }
  @else if $media == medium {
    @media only screen and (min-width: $break-medium +1) and (max-width: $break-big) { @content; }
  }
  @else if $media == small {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-medium) { @content; }
  }
    @else if $media == xsmall {
    @media only screen and (min-width: $break-xsmall) and (max-width: $break-small) { @content; }
  }

}

@mixin menu-sm(){
display:none;
}


$b-xs: 320px;
$b-sm: 576px;
$b-md: 768px;
$b-lg: 992px;
$b-xl: 1200px;
$b-xl-2: 1500px;
$b-xl-3: 1700px;

@mixin respond-from($media) {
  //start additional
  @if $media == xl3 {
    @media only screen and (min-width: $b-xl-3) { @content; }
  }
  @if $media == xl2 {
    @media only screen and (min-width: $b-xl-2) { @content; }
  }
  //end additional
  @if $media == xl {
    @media only screen and (min-width: $b-xl) { @content; }
  }
   @if $media == lg {
    @media only screen and (min-width: $b-lg){ @content; }
  }
  @else if $media == md {
    @media only screen and (min-width: $b-md) { @content; }
  }
  @else if $media == sm {
    @media only screen and (min-width: $b-sm) { @content; }
  }
    @else if $media == xs {
    @media only screen and (min-width: $b-xs) { @content; }
  }

}

@mixin respond-for($orientation){

  @if $orientation == portrait{
    @media only screen and (orientation:portrait){ @content; }
  }
  @if $orientation == landscape{
    @media only screen and (orientation:landscape) and (max-width:991px) { @content; }
  }
}


