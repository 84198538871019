@import "media";

@mixin container{
	max-width: $container-width;
	width: 100%;
	margin: 0 auto;
	padding-left:20px;
	padding-right:20px;
	left: 0;
	right: 0;
}
@mixin head-container{
	
	width: 100%;
	left: 0;
	right: 0;
}
@mixin header{
	position: absolute;
	z-index: 9;
	width: 100%;
	margin-top: 40px;
}
@mixin section-margin{
	margin-top: 100px!important;
	margin-bottom: 100px!important;
}

@mixin carousel{
	width: 100%;
	height: 100vh;
	display: block;
	position: relative;
	.overlay{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
		filter: alpha(opacity=70);
		opacity: 0.8;
		background-color: #000;
	}
}

@mixin transition($transition:none){
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}

// FONT SIZE TRANSFORM REM
@function calculateRem($size) {
	$remSize: $size / 16px;
	@return #{$remSize}rem;
}

@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
}

.l-0{
	left: 0px !important;
}
.r-0{
	right:0px !important;
}
.t-0{
	top:0px !important;
}
.t-auto{
	top: auto !important;
}
.b-0{
	bottom:0px !important;
}

//=====OPACITY=======//

@function strip-units($number) {
  @return $number / (100);
}

@for $i from 1 through 100 {
    $width: strip-units($i);

    .opacity-#{$i} { 
        opacity: $width;
    }
    .opacity-md-#{$i} { 
    	@include respond-from(md){
        opacity: $width;
    }
    }

}

//=====PERCENTAGE-WIDTH=======//

@for $i from 1 through 100 {
    $widt: strip-units($i) * 100%;

    .w-#{$i} { 
        width: $widt;
    }
}


//=====FONT-CLASS=======//


@for $i from 10 through 30 { 


	
	.font-#{$i} { 

		font-size: #{$i}px; 
		font-size: #{$i/16}rem;
		

	} 

	.font-sm-#{$i}{
		@include respond-from(sm){

			font-size: #{$i}px; 
		font-size: #{$i/16}rem; 
		}

	}

	.font-md-#{$i}{

		@include respond-from(md){
			font-size: #{$i}px; 
		font-size: #{$i/16}rem; 
		}
		
	}

	.font-lg-#{$i}{

		@include respond-from(lg){
			font-size: #{$i}px; 
		font-size: #{$i/16}rem; 
		}
		
	}

	.font-xl-#{$i}{

		@include respond-from(xl){
			font-size: #{$i}px; 
		font-size: #{$i/16}rem; 
		}
		
	}
} 

//=====GENERATE-HEIGHT=======//

@function sheight-num($number) {
  @return $number * 10;
}

@for $i from 1 through 10 { 

	.h-#{$i*10}{

		height: $i*10% !important;
	}
	.h-sm-#{$i*10}{
		@include respond-from(sm){
		height: $i*10% !important;
		}
	}
	.h-md-#{$i*10}{
		@include respond-from(md){
		height: $i*10% !important;
		}
	}
	.h-lg-#{$i*10}{
		@include respond-from(lg){
		height: $i*10% !important;
		}
	}
	.h-xl-#{$i*10}{
		@include respond-from(xl){
		height: $i*10% !important;
		}
	}

	//VIEWPORT

	.vh-#{$i*10}{

		height: $i*10vh;max-height: $i*10vh;
	}
	.vh-sm-#{$i*10}{
		@include respond-from(sm){
		height: $i*10vh;
		max-height: $i*10vh;
		}
	}
	.vh-md-#{$i*10}{
		@include respond-from(md){
		height: $i*10vh;
		max-height: $i*10vh;
		}
	}
	.vh-lg-#{$i*10}{
		@include respond-from(lg){
		height: $i*10vh;
		max-height: $i*10vh;
		}
	}
	.vh-xl-#{$i*10}{
		@include respond-from(xl){
		height: $i*10vh;
		max-height: $i*10vh;
		}
	}
}

//=====PADDING-MARGIN=======//

@for $i from 1 through 10 { 

	.mt-{
		&#{$i*10}{
		margin-top: $i*10px;
		}
		&sm-#{$i*10}{
			@include respond-from(sm){
				margin-top: $i*10px;
			}
		}
		&md-#{$i*10}{
			@include respond-from(md){
				margin-top: $i*10px;
			}
		}
		&lg-#{$i*10}{
			@include respond-from(lg){
				margin-top: $i*10px;
			}
		}
		&xl-#{$i*10}{
			@include respond-from(xl){
				margin-top: $i*10px;
			}
		}
	}
	.mb-{
		&#{$i*10}{
		margin-bottom: $i*10px;
		}
		&sm-#{$i*10}{
			@include respond-from(sm){
				margin-bottom: $i*10px;
			}
		}
		&md-#{$i*10}{
			@include respond-from(md){
				margin-bottom: $i*10px;
			}
		}
		&lg-#{$i*10}{
			@include respond-from(lg){
				margin-bottom: $i*10px;
			}
		}
		&xl-#{$i*10}{
			@include respond-from(xl){
				margin-bottom: $i*10px;
			}
		}
	}
	.ml-{
		&#{$i*10}{
		margin-left: $i*10px;
		}
		&sm-#{$i*10}{
			@include respond-from(sm){
				margin-left: $i*10px;
			}
		}
		&md-#{$i*10}{
			@include respond-from(md){
				margin-left: $i*10px;
			}
		}
		&lg-#{$i*10}{
			@include respond-from(lg){
				margin-left: $i*10px;
			}
		}
		&xl-#{$i*10}{
			@include respond-from(xl){
				margin-left: $i*10px;
			}
		}
	}
	.mr-{
		&#{$i*10}{
		margin-right: $i*10px;
		}
		&sm-#{$i*10}{
			@include respond-from(sm){
				margin-right: $i*10px;
			}
		}
		&md-#{$i*10}{
			@include respond-from(md){
				margin-right: $i*10px;
			}
		}
		&lg-#{$i*10}{
			@include respond-from(lg){
				margin-right: $i*10px;
			}
		}
		&xl-#{$i*10}{
			@include respond-from(xl){
				margin-right: $i*10px;
			}
		}
	}
	.mx-{
		&#{$i*10}{
		margin-left: $i*10px;
		margin-right: $i*10px;
		}
		&sm-#{$i*10}{
			@include respond-from(sm){
				margin-left: $i*10px;
				margin-right: $i*10px;
			}
		}
		&md-#{$i*10}{
			@include respond-from(md){
				margin-left: $i*10px;
				margin-right: $i*10px;
			}
		}
		&lg-#{$i*10}{
			@include respond-from(lg){
				margin-left: $i*10px;
				margin-right: $i*10px;
			}
		}
		&xl-#{$i*10}{
			@include respond-from(xl){
				margin-left: $i*10px;
				margin-right: $i*10px;
			}
		}
	}
	.my-{
		&#{$i*10}{
		margin-top: $i*10px;
		margin-bottom: $i*10px;
		}
		&sm-#{$i*10}{
			@include respond-from(sm){
				margin-top: $i*10px;
				margin-bottom: $i*10px;
			}
		}
		&md-#{$i*10}{
			@include respond-from(md){
				margin-top: $i*10px;
				margin-bottom: $i*10px;
			}
		}
		&lg-#{$i*10}{
			@include respond-from(lg){
				margin-top: $i*10px;
				margin-bottom: $i*10px;
			}
		}
		&xl-#{$i*10}{
			@include respond-from(xl){
				margin-top: $i*10px;
				margin-bottom: $i*10px;
			}
		}
	}

	.pt-{
		&#{$i*10}{
		padding-top: $i*10px;
		}
		&sm-#{$i*10}{
			@include respond-from(sm){
				padding-top: $i*10px;
			}
		}
		&md-#{$i*10}{
			@include respond-from(md){
				padding-top: $i*10px;
			}
		}
		&lg-#{$i*10}{
			@include respond-from(lg){
				padding-top: $i*10px;
			}
		}
		&xl-#{$i*10}{
			@include respond-from(xl){
				padding-top: $i*10px;
			}
		}
	}
	.pb-{
		&#{$i*10}{
		padding-bottom: $i*10px;
		}
		&sm-#{$i*10}{
			@include respond-from(sm){
				padding-bottom: $i*10px;
			}
		}
		&md-#{$i*10}{
			@include respond-from(md){
				padding-bottom: $i*10px;
			}
		}
		&lg-#{$i*10}{
			@include respond-from(lg){
				padding-bottom: $i*10px;
			}
		}
		&xl-#{$i*10}{
			@include respond-from(xl){
				padding-bottom: $i*10px;
			}
		}
	}
	.pl-{
		&#{$i*10}{
		padding-left: $i*10px;
		}
		&sm-#{$i*10}{
			@include respond-from(sm){
				padding-left: $i*10px;
			}
		}
		&md-#{$i*10}{
			@include respond-from(md){
				padding-left: $i*10px;
			}
		}
		&lg-#{$i*10}{
			@include respond-from(lg){
				padding-left: $i*10px;
			}
		}
		&xl-#{$i*10}{
			@include respond-from(xl){
				padding-left: $i*10px;
			}
		}
	}
	.pr-{
		&#{$i*10}{
		padding-right: $i*10px;
		}
		&sm-#{$i*10}{
			@include respond-from(sm){
				padding-right: $i*10px;
			}
		}
		&md-#{$i*10}{
			@include respond-from(md){
				padding-right: $i*10px;
			}
		}
		&lg-#{$i*10}{
			@include respond-from(lg){
				padding-right: $i*10px;
			}
		}
		&xl-#{$i*10}{
			@include respond-from(xl){
				padding-right: $i*10px;
			}
		}
	}
	.px-{
		&#{$i*10}{
		padding-left: $i*10px;
		padding-right: $i*10px;
		}
		&sm-#{$i*10}{
			@include respond-from(sm){
				padding-left: $i*10px;
				padding-right: $i*10px;
			}
		}
		&md-#{$i*10}{
			@include respond-from(md){
				padding-left: $i*10px;
				padding-right: $i*10px;
			}
		}
		&lg-#{$i*10}{
			@include respond-from(lg){
				padding-left: $i*10px;
				padding-right: $i*10px;
			}
		}
		&xl-#{$i*10}{
			@include respond-from(xl){
				padding-left: $i*10px;
				padding-right: $i*10px;
			}
		}
	}
	.py-{
		&#{$i*10}{
		padding-top: $i*10px;
		padding-bottom: $i*10px;
		}
		&sm-#{$i*10}{
			@include respond-from(sm){
				padding-top: $i*10px;
				padding-bottom: $i*10px;
			}
		}
		&md-#{$i*10}{
			@include respond-from(md){
				padding-top: $i*10px;
				padding-bottom: $i*10px;
			}
		}
		&lg-#{$i*10}{
			@include respond-from(lg){
				padding-top: $i*10px;
				padding-bottom: $i*10px;
			}
		}
		&xl-#{$i*10}{
			@include respond-from(xl){
				padding-top: $i*10px;
				padding-bottom: $i*10px;
			}
		}
	}
	//PERCENTAGE
		.t-{
			&#{$i*10}p{
			top: $i*10% !important;
			}
			&md-#{$i*10}p{
				@include respond-from(md){
			top: $i*10% !important;
			}
			}
			&lg-#{$i*10}p{
				@include respond-from(lg){
			top: $i*10% !important;
			}
			}
		
		}
		.l-{
			&#{$i*10}p{
			left: $i*10% !important;
			}

			&lg-#{$i*10}p{
				@include respond-from(lg){
			left: $i*10% !important;
			}
			}
		}
		
		

	//PX

		.l-{
			&#{$i*10}{
			left: $i*10px !important;
			}
			&lg-#{$i*10}{
				@include respond-from(lg){
			left: $i*10% !important;
			}
			
		
		}
		}
		.b-{
			&#{$i*10}{
			bottom: $i*10px !important;
			}
			&md-#{$i*10}{
			@include respond-from(md){
			bottom: $i*10px !important;
			}
			}
			&lg-#{$i*10}{
			@include respond-from(lg){
			bottom: $i*10px !important;
			}
			}

			
		}
		.r-{
			&#{$i*10}{
			right: $i*10px !important;;
			}
			
		}
		.t-{
			&#{$i*10}{
			top: $i*10px !important;
			}
			&md-#{$i*10}{
				@include respond-from(md){
			top: $i*10px !important;
			}
			}

			&lg-#{$i*10}{
				@include respond-from(lg){
			top: $i*10px !important;
			}
			}
			
		
	
		}


}








