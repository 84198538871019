
//===========BURGER-ICON===========//
.burger{
  width: 45px;
  height: 28px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  z-index:999;
}
.burger span{
  background-color:$primary-color;
  position: absolute;
  transition: .3s cubic-bezier(.8, .5, .2, 1.4);
  width:100%;
  height: 1px;
}
.burger span:nth-child(1){
  top:0px;
  left: 0px;
}
.burger span:nth-child(2){
  top:13px;
  left: 0px;
}
.burger span:nth-child(3){
  bottom:0px;
  left: 0px;
}
.burger:not(.burger-open):hover span:nth-child(1){
  transform: scaleX(.8);
}
.burger:not(.burger-open):hover span:nth-child(2){
  transform: scaleX(.5);
}
.burger:not(.burger-open):hover span:nth-child(3){
  transform: scaleX(.8);
}
.burger-open{
  span{
    background:#fff;
  }
}
.burger-open span:nth-child(1){
  transform: rotate(135deg);
  top: 13PX;

}
.burger-open span:nth-child(2){
  transform: scale(0);
  transition-duration: 50ms


}
.burger-open span:nth-child(3){
  top: 13PX;
  transform: rotate(-135deg);
}

//==================MENU-BURGER============//

.menu-burger{
    z-index: 99;
    font-size: 0.8em;
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    background: $primary-color;
    position: fixed;
    left: 0px;
    top: 0px;
    text-align: center;
    height:100vh;
    align-items: center;
    justify-content: center;
    display:flex;


      ul{
        padding:0px;    
        list-style-type:none;
          li{

            a{

              color:#fff;
              font-size: 22px;
              @include respond-from(sm){
                font-size: 26px;
              }
              @include respond-from(md){
                font-size: 30px;
              }
              @include respond-from(lg){
                font-size: $h2-font-size;
              }
              
              font-family: $primary-font;
              font-weight: $weight-strong;
              display:inline-block;
              position:relative;
              text-decoration:none;
              &:hover{
                color:#fff !important;
              }

              &:after{
                  content: "";
                  height: 2px;
                  width: 0%;
                  background: #fff;
                  position: absolute;
                  bottom: 10px;
                  left: 0px;
                  margin:0 auto;
                  right: 0px;

                  -webkit-transition: all 1s ease;
                  -moz-transition: all 1s ease;
                  -ms-transition: all 1s ease;
                  -o-transition: all 1s ease;
                  transition: all 1s ease;
                  cursor: pointer;

                  }
            }

            &.active,&:hover{
              a{
                &:after{
                  width: 53%;
                }
              }
              
            }
            
        }
    }
}


.glyphicon-home{
  color:white; 
  font-size:1.5em; 
  margin-top:5px; 
  margin:0 auto;
}

#header_nav .navbar{
  height: 80px !important; 
}