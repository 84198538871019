section{

	max-height: 100vh;
	overflow:hidden;
	width:100%;

}

.container{
	/*@include respond-to(xsmall){
		max-width: 90% !important;
	}*/
	@include respond-from(xl2){
		max-width: 1400px;

	}
	@include respond-from(xl3){
		max-width: 1600px;
		
	}
}

//======BASIC-STYLES======//
h1{
	
	position:relative;
	transition: all 0.8s ease;
	&:after{
		    content: "";
		    width: 20%;
		    height: 1px;
		    background: #1dbed7;
		    left: 0px;
		    right: 0;
		    position: absolute;
		    margin: 0 auto;
		    bottom: -15px;
		    text-align: center;
			}
}

h2{
	position:relative;
	margin-bottom: 30px;
	@include respond-from(md){
		margin-bottom: 50px;
	}
	&:after{
			content: "";
		    width: 20%;
		    height: 1px;
		    background: #1dbed7;
		    left: 0px;
		    position: absolute;
		    margin: 0 auto;
		    bottom: -10px;
		    text-align: center;
			}
}


.video{
	position: absolute;
	right: 0;
	bottom: 0;
	min-width: 100%; 
	min-height: 100%;
	display:none;
	opacity: 1;
	transition: all 0.8s ease;

	&.active{
		display:block;
	}
	&.half{
		right: -50px;
	}
}


.preload{

	.video{
		opacity:0;
	}
	h1{
		color:#000;
	}
	.scroll-down{
		display:none;
	}

	.preload-bar{
		display:block;
	}
	.container{
		background-color:#fff !important;
	}
}
.preload-bar{
	display:none;
	position: absolute;
    bottom: 0px;
    height: 8px;
    width: 0%;
    background: $primary-color;
    
}




//======HEADER======//

header{

	position:fixed !important;
	.navbar{

		padding-top:2rem;
		padding-right: 2rem;	
			.logo{
				width:48px;
				height:100%;


			}


	}
}

//======FIRST-SECTION======//


.hero-area{

	.scroll-down{
		position:absolute;
		left:0;
		right:0;
		margin: 0 auto;
		bottom: 0px;
		transform: scale(0.6);
		@include respond-for(landscape){
			transform: scale(0.6);
			bottom: -20px;
		}
		@include respond-from(lg){
		bottom:100px;
		}

		svg{
			
			.line-arrow,.arrow{
				animation: mouse-scroll 1.6s infinite;
			}
		}
	}

	.media-video{
		img{
				width: 100%;
			    left: 0% !important;
			    height: 100%;
			    position: absolute !important;
			    top: 0;
			    vertical-align: middle;
			    object-fit: cover;
			    display:block;
			    opacity:0.7;
			    @include respond-from(sm){
			    	display:none;
			    }
		}
	}

	.container{
		    background: rgba(0,0,0,1);
		    @include respond-from(sm){
		    	background: transparent;
		    }
	}
}

//======WE-DO-SECTION======//

.media-img{
	bacgkround-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height:100%;
	&.carousel-part{
		min-height:100vh;
	}
}
.we-do{
	.video-part{
			min-height: 40vh;
			max-height: 40vh;
		@include respond-for(landscape){
			min-height: 100vh;
			max-height:100vh;
			max-width: 50%;
		}
		@include respond-from(lg){
			min-height: 100vh;
			max-height:100vh;
		}


		.media-img{
			background-image: url("./images/co-delame.jpg");
		}
	}
	.container{
		height: 60%;
		display:flex;
		@include respond-from(lg){
			height:auto;
			display:block;
		}
		@include respond-for(landscape){
			max-width: 50%;
			position: absolute;
    		top: 50%;
    		height: 100%;
    		right:0;
    		transform: translate(0%, -50%);
    		align-items:center;
    		padding-top:0px !important;
    		padding-left: 15px;
    		padding-right: 15px;
		}
		.pt-5{
			@include respond-for(landscape){
			padding-top: 0px !important;
			}
		}
		.list-item{
			@include font-size(18px);

			@include respond-from(sm){
				@include font-size(20px);
			}
			@include respond-from(md){
				@include font-size(30px);
			}
			@include respond-for(landscape){
			@include font-size(20px);
			}
		}
	}
}

//======FOR-WHO-SECTION======//

.for-who{

	.row{
		@include respond-for(landscape){
			-ms-flex-direction: row-reverse!important;
		    flex-direction: row-reverse!important;
		}
		.video-part{
				min-height: 40vh;
				max-height: 40vh;
			@include respond-for(landscape){
				min-height: 100vh;
				max-height:100vh;
				max-width: 50%;
			}
			@include respond-from(lg){
				min-height: 100vh;
				max-height:100vh;
			}
			.media-img{
				background-image: url("./images/pro-koho-pracujeme.jpg");
			}
		}
	}

	.container{
		height: 60%;
		@include respond-from(lg){
			height:auto;
			display:block;
		}
		@include respond-for(landscape){
			max-width: 50%;
			position: absolute;
    		top: 50%;
    		height:100%;
    		left:0;
    		transform: translate(0%, -50%);
    		padding-left: 15px;
    		padding-right: 15px;
		}
		.row{
			@include respond-for(landscape){
				padding-top:0px;
				align-items:center;
			}
			height:100%;
			.clients{
				img{
					max-height: 80px;
					padding-right: 20px;
				}
			}
		}

		.pt-5{
			@include respond-for(landscape){
			padding-top: 0px !important;
			}
		}
	}

	
}


//======REFERENCE-SECTION======//

.reference{
	background-color: $color-grey2;
	min-height: 100vh;
	max-height:100vh;

	.carousel-inner{



		height:50vh;
		@include respond-for(landscape){
		height:100vh !important;
		}
		@include respond-from(lg){
		height:100vh;
		}
		.arrows{
			width: 100%;
			position: absolute;
			top: 0;
			height:40%;
			bottom: 0;
			right: 0;
			z-index: 1;
			@include respond-for(landscape){
				width:48%;
				height:100%;
				left:0;
			}
			@include respond-from(lg){
				width:58%;
				height:100%;
			}

		}

			.carousel-item{
			
				
					.video-part{
						height:40vh;
						max-height:40vh;

						@include respond-for(landscape){
						max-width:50%;
						height:100vh !important;
						max-height:100vh;
						}
						@include respond-from(lg){
						height:100vh;
						max-height:100vh;
						}
						.col-lg-7{
							@include respond-for(landscape){
								height:100vh;
								max-height:100vh;
							}
						}

						.container{
							height: 54vh !important;
							@include respond-from(lg){
								height:100% !important;
							}

						}
					}
				
			}

		}
	

	.clients{
		img{
			max-height: 80px;

		}
	}

	.container{
		@include respond-from(lg){
			height:auto;
			display:block;
		}
		@include respond-for(landscape){
			max-width: 50%;
			position: absolute;
    		top: 50% !important;
    		height:100% !important;
    		right:0 !important;
    		left: auto !important;
    		transform: translate(0%, -50%);
    		padding-left: 15px;
    		padding-right: 15px;
    		padding-top:0px !important;
    		
		}
		.row{
			align-items: center;

		}
	}

}

#myCarousel,#myCarouselv2{
	
	.carousel-item{
		height:100%;
		img,video{  
				width: 120%;
			    left: -10% !important;
			    height: 100%;
			    position: absolute !important;
			    top: 0;
			    vertical-align: middle;
			   	object-fit: cover;
			    display:block;
			    &.ref-img{
			    	width:100%;
			    	height:100%;
			    	top: 50%;
   					transform: translate(-50%, -50%);
    				left: 50% !important;

			    }
		}
	}

	.carousel-indicators {
		    right: 0;
		    top: 10px;
		    left: 0;
		    z-index: 1;
		    display: -ms-flexbox;
		    display: flex;
		    -ms-flex-pack: start;
		    justify-content: start;
		    padding-left: 0;
		    margin-right: 0%;
		    margin-left: 0%;
		    list-style: none;
		    margin-left: -7px;

		  
		    & li{
		    	
		    	position: relative;
    			-ms-flex: 0 1 auto;
    			flex: 0 1 auto;
    			margin-right: 0px;
    			margin-left: 0px;
    			text-indent: -999px;
			    width: 10px !important;
    			height: 10px !important;
			   	padding-top: 15px;
    			padding-bottom: 15px;
			   	padding-right: 30px;
			    background-color: transparent !important;

			    &:before{
			    	background-color: $color-grey1  !important;
			    	position: absolute;
			    	top:5px;
			    	left:0;
			    	right:0;
			    	width: 10px !important;
    				height: 10px !important;
    				border-radius: 50%;
    				margin: 0 auto;

			    }
			    &.active{
   					&:before{
   							background-color: $primary-color  !important;
   					}

			    }
			    
			

		    }
	}

	&v2{
		.carousel-indicators {
		& li{
			&:before{
			background-color: $color-white !important;
			}
		}
	}
	}
}






//======ABOUT-US======//

.about-us{
	min-height: 100vh;
	max-height:100vh;

	#myCarouselv2{	
		height: 100vh;





	.carousel-inner{
		height:40vh;
		width:100% !important;
		@include respond-for(landscape){
		height:100vh;
		width:50% !important;
		}
		@include respond-from(lg){
		height:100vh;
		width:50% !important;

		}

		.arrows{
			width: 100%;
			height:100%;
			position: absolute;
			top: 10%;
			bottom: 0;
			left: 0;
			z-index: 1;

			@include respond-from(lg){
				width: 48%;
				top: 0%;
				height:100%;
			}
			@include respond-for(landscape){
				width: 48%;
				top: 0%;
				height:100%;
			}
		}

		.carousel-item{
		
			
				.video-part{
					height:40vh;
					max-height:40vh;

					@include respond-for(landscape){
					max-width:100%;
					height:100vh;
					max-height:100vh;
					}
					@include respond-from(lg){
					height:100vh;
					max-height:100vh;
					}
				}

				.container{

					max-width: 100% !important;
					@include respond-from(sm){

					    margin-left: calc( ( 100vw - 540px ) / 2 ) !important;
						}

						@include respond-from(md){

					    margin-left: calc( ( 100vw - 	720px ) / 2 ) !important;
						}

						@include respond-for(landscape){
							margin-left:0px !important;
						}

					

					@include respond-from(lg){

					    margin-left: calc( ( 100vw - 960px ) / 2 ) !important;
						}

					@include respond-from(xl){

					    margin-left: calc( ( 100vw - 1140px ) / 2 ) !important;
						}
						@include respond-from(xl2){

					    margin-left: calc( ( 100vw - 1400px ) / 2 ) !important;
						}
						@include respond-from(xl3){

					    margin-left: calc( ( 100vw - 1600px ) / 2 ) !important;
						}
					}
				
			
		}

	}

	
	.container{
		height:60% !important;

		@include respond-from(lg){
			height:auto !important;
			display:block;
		}
		@include respond-for(landscape){
			max-width: 50%;
			position: absolute;
    		top: 50%;
    		height:100% !important;
    		right:0 !important;
    		left: auto !important;
    		transform: translate(0%, -50%);
    		padding-left: 15px;
    		padding-right: 15px;
    		padding-top:0px !important;
    		
    		
    		
		}
		&.inside{
			@include respond-for(landscape){
			height: auto !important;
    		left: 0px !important;
    		right: auto !important;
			left:0px !important;
			transform: none !important;

			}
		}
		.row{
			
    			height: 100%;
    			@include respond-for(landscape){
    			padding-top:0px !important;
    			align-items: center;
    			}

		}
	}
	&>.container{
		&.inside{
			height:40% !important;
			@include respond-from(lg){
				height: auto !important;
			}

			@include respond-for(landscape){
				height:100% !important; 
				
			}
			&>.col-12{
				
				height:100%;
				position:relative;
				

				&>.carousel-indicators{

					bottom: 0rem !important;
					top: auto !important;
					@include respond-from(lg){
							top:10px !important;
							bottom: auto !important;
					}
				}
			}
		}
	}
	
	
}

a[href^="tel"] {
			color: $primary-color !important;
		}
		a[x-apple-data-detectors] {
			color: $primary-color !important;
		}

}



.footer{
	height:500px;
	max-height:500px;
	overflow:hidden;

	.media-img{
		img{
			    left: 50% !important;
			    top: 50% !important;
			    transform: translate(-50%,-50%);
			    height: 100%;
			    position: absolute;
			}
		}

		a{
			&:hover{
				color:#fff !important;
			}
		}

		a[href^="tel"] {
			color: #fff !important;
		}
		a[x-apple-data-detectors] {
			color: #fff !important;
		}
}
.fp-tableCell {
    display: grid;
    width: 100%;
}

